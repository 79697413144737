import React from 'react'
import Helmet from 'react-helmet'

import Img from 'gatsby-image'
import Layout from '../layout'
import Carousel from '../components/Carousel'
import SimpleSlider from '../components/Slider'
import PostListing from '../components/PostListing'
import SEO from '../components/SEO'
import config from '../../data/SiteConfig'
import { StaticQuery, graphql } from "gatsby"
import Slider from "react-slick"



const PookPage = (props) => (
  <Layout>
    <main>
      <Helmet title={`Pook | ${config.siteTitle}`}>
    <link
      rel="stylesheet"
      type="text/css"
      charset="UTF-8"
      href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
    />
    <link
      rel="stylesheet"
      type="text/css"
      href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
    />
</Helmet>


      <h1>Contact us</h1>
      
      <p>Contact informations</p>
      <Img fluid={props.data.imageOne.childImageSharp.fluid} />
      <Img fluid={props.data.imageTwo.childImageSharp.fluid} />
      <Img fluid={props.data.imageThree.childImageSharp.fluid} />
	<Carousel />
    </main>
  </Layout>
)

export default PookPage

/* eslint no-undef: "off" */
export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "images/avatar.jpg" }) {
      ...fluidImage
    }
    imageTwo: file(relativePath: { eq: "images/park.jpg" }) {
      ...fluidImage
    }
    imageThree: file(relativePath: { eq: "gallery/slide1.jpg" }) {
      ...fluidImage
    }
  }
`
