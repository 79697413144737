import React from "react";
import Slider from "react-slick";
import { StaticQuery, graphql } from "gatsby"
// export const fluidimage = graphy
import Img from 'gatsby-image'

export const fluidImage = graphql`
 fragment fluidImage on File {
  childImageSharp {
   fluid(maxWidth: 1000) {
   ...GatsbyImageSharpFluid
   }
   }
   }
   `;

export const heyQuery = graphql`
 query {
 imageOne: file(relativePath: { eq: "gallery/slide1.jpg" }) {
   ...fluidImage
   }
 imageTwo: file(relativePath: { eq: "gallery/slide2.jpg" }) {
   ...fluidImage
   }
  imageThree: file(relativePath: { eq: "gallery/slide3.jpg" }) {
   ...fluidImage
   }
  } 
`

class SimpleSlider extends React.Component {
  render () {
    var settings = {
	 dots: true,
	 infinite: true,
	 speed: 500,
	 slidesToShow: 1,
	 slidesToScroll: 1
	};
return (
	<Slider {...settings}>
	<div>
	<Img fluid={data.imageOne.childImageSharp.fluid} />
	</div>
	<div>
	<Img fluid={data.imageTwo.childImageSharp.fluid} />
	</div>
	<div>
	<Img fluid={data.imageThree.childImageSharp.fluid} />
	</div>
	</Slider>
);
  }
}
export default SimpleSlider
